import React from "react";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.min.css";
import * as SurveyTheme from "survey-core/themes";
import "./index.css";
import { json } from "./json";
import axios from "axios";

const test_url =
  "https://youautomate.click/webhook-test/f93fa00c-1d47-43cd-82e2-629a4e61ba26";
const prod_url =
  "https://youautomate.click/webhook/f93fa00c-1d47-43cd-82e2-629a4e61ba26";

function SurveyComponent() {
  const survey = new Model(json);
  survey.applyTheme(SurveyTheme.DefaultDark);
  survey.completeText="Schedule Post"
  survey.onComplete.add((sender, options) => {
    console.log(JSON.stringify(sender.data, null, 3));

    const data = {
      ...sender.data,
      wait_time: calculateSecondsBetween(sender.data.schedule)
    };
    axios({
      method: "post",
      url: prod_url,
      withCredentials: false,
      data,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Credentials": "true"
      }
    })
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  });

  const calculateSecondsBetween = function (dateTime) {
    // Parse the input dateTime string to a Date object
    const targetDate = new Date(dateTime);

    // Get the current date and time
    const now = new Date();

    // Calculate the difference in milliseconds
    const differenceInMillis = targetDate - now;

    // Convert milliseconds to seconds
    const differenceInSeconds = Math.floor(differenceInMillis / 1000);

    return differenceInSeconds;
  };

  // Example usage:
  const dateTime = "2024-06-28T17:49";
  console.log(calculateSecondsBetween(dateTime));
  return <Survey model={survey} />;
}

export default SurveyComponent;
