export const json = {
  "title": "Schedule Community post",
  "logoPosition": "right",
  "completedHtml": "<h3>Thank you! your post has been sheduled! </h3>",
    pages: [
      {
        name: "page1",
        elements: [
          {
            type: "text",
            name: "title",
            title: "Title",
            isRequired: true
          },
          {
            type: "comment",
            name: "content",
            title: "Content",
            isRequired: true
          },
          {
            type: "dropdown",
            name: "category",
            title: "category",
            isRequired: true,
            choices: [
              {
                value: "General discussion",
                text: "General discussion"
              },
              {
                value: "Category 2",
                text: "Category 2"
              },
              {
                value: "Category 3",
                text: "Category 3"
              }
            ]
          },
          {
            type: "text",
            name: "schedule",
            title: "Scheduled Time",
            isRequired: true,
            inputType: "datetime-local",
            min: "2024-06-28T16:54"
          },
          {
            type: "text",
            name: "skool_email",
            title: "Skool Email",
            isRequired: true
          },
          {
            type: "text",
            name: "skool_password",
            title: "Skool Password",
            isRequired: true,
            inputType: "password"
          }
        ]
      }
    ]
  };
  